import React from 'react'

import { Route, Redirect } from 'react-router-dom'

const user = JSON.parse(localStorage.getItem('user')) || {}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user.dsToken && !user.resetPassword ? (
        (user.office || {}).tipCargo === 'OPERAÇÃO' ? (
          props.location.pathname === '/lancamento' ||
          props.location.pathname === '/entries' ||
          props.location.pathname === '/entry-report' ||
          props.location.pathname === '/entry-vacation' ||
          props.location.pathname === '/hour-bank-consult' ||
          props.location.pathname === '/entry' ? (
            <Component {...props} />
          ) : props.location.pathname === '/tasks-history' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/home' }} />
          )
        ) : (user.office || {}).tipCargo === 'COORDENADOR' ? (
          props.location.pathname === '/entry-vacation' ||
          props.location.pathname === '/lancamento' ||
          props.location.pathname === '/entries' ||
          props.location.pathname === '/entry-report' ||
          props.location.pathname === '/entry-report-fechamento-alocacaoRD' ||
          props.location.pathname === '/entry-report-fechamento-squadRD' ||
          props.location.pathname === '/entry' ? (
            <Component {...props} />
          ) : props.location.pathname === '/tasks' ? (
            <Component {...props} />
          ) : props.location.pathname === '/hour-bank-consult' ? (
            <Component {...props} />
          ) : props.location.pathname === '/user-period' ? (
            <Component {...props} />
          ) : props.location.pathname === '/point' ||
            props.location.pathname === '/points' ||
            props.location.pathname === '/pontos' ? (
            <Component {...props} />
          ) : props.location.pathname === '/tasks-history' ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/home' }} />
          )
        ) : (user.office || {}).tipCargo === 'ADMINISTRADOR' ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      ) : (
        <Redirect to={{ pathname: '/' }} />
      )
    }
  />
)

export default PrivateRoute
