import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import PrivateRoute from './authentication'

import Clients from '../scripts/containers/Client'
import ClientPeriod from '../scripts/containers/ClientPeriod'
import Entry from '../scripts/containers/Entry'
import Home from '../scripts/containers/Home'
import HourBank from '../scripts/containers/HourBank'
import HourBankConsult from '../scripts/containers/HourBankConsult'
import Login from '../scripts/containers/Login'
import MonthlyClose from '../scripts/containers/MonthlyClose'
import Offices from '../scripts/containers/Offices'
import Period from '../scripts/containers/Period'
import Point from '../scripts/containers/Point'
import Projects from '../scripts/containers/Project'
import Secure from '../scripts/containers/Secure'
import Tasks from '../scripts/containers/Tasks'
import Users from '../scripts/containers/User'
import UserPeriod from '../scripts/containers/UserPeriod'
import UserTask from '../scripts/containers/UserTask'
import FilterTest from '../scripts/containers/FilterTest'
import TasksHistory from '../scripts/containers/StatusHistory/StatusHistory'
import Password from '../scripts/containers/Password'
import Employee from '../scripts/containers/Employee'
import EntryReport from '../scripts/containers/EntryReport'
import EntryReportFechamentoRD from '../scripts/containers/EntryReportFechamentoRD'
import Proposal from '../scripts/containers/Proposal'
import Vacation from '../scripts/containers/Vacation'
import ProposalFunction from '../scripts/containers/ProposalFunction'
import ProposalUser from '../scripts/containers/ProposalUser'
import ForgotPassword from '../scripts/containers/ForgotPassword'
import EntryReportFechamentoRDSquad from '../scripts/containers/EntryReportFechamentoRDSquad'
import PreClosure from '../scripts/containers/PreClosure'
import PreClosureExecutionUser from '../scripts/containers/PreClosureExecutionUser'
import EntryADM from '../scripts/containers/EntryADM'
import EntryAutomatic from '../scripts/containers/EntryAutomatic'
import ClosureClients from '../scripts/containers/ClosureClients'
import PunchClockReportRD from '../scripts/containers/PunchClockReportRD'
import EmployeeSearch from '../scripts/containers/EmployeeSearch'

import TimeCheck from '../scripts/containers/TimeCheck'

export default function RoutesMain() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={['/', '/login']} component={Login} />
        <Route exact path="/home" component={Secure(Home)} />
        <PrivateRoute exact path="/forgot-password" component={ForgotPassword} />
        <PrivateRoute exact path="/change-password" component={Secure(Password)} />
        <PrivateRoute exact path="/clients" component={Secure(Clients)} />
        <PrivateRoute exact path="/colaborador" component={Secure(Employee)} />
        <PrivateRoute exact path="/projects" component={Secure(Projects)} />
        <PrivateRoute exact path="/offices" component={Secure(Offices)} />
        <PrivateRoute exact path="/users" component={Secure(Users)} />
        <PrivateRoute
          exact
          path={['/point', '/points', '/pontos']}
          component={Secure(Point)}
        />
        <PrivateRoute
          exact
          path={['/period', '/periods', '/periodos']}
          component={Secure(Period)}
        />
        <PrivateRoute
          exact
          path={['/lancamento', '/entries', '/entry']}
          component={Secure(Entry)}
        />
        <PrivateRoute
          exact
          path={['/lancamentoADM', '/entriesADM', '/entryADM']}
          component={Secure(EntryADM)}
        />
        <PrivateRoute
          exact
          path={['/entriesAutomatic', '/EntryAutomatic']}
          component={Secure(EntryAutomatic)}
        />
        <PrivateRoute
          exact
          path={['/closureClients', '/ClosureClients']}
          component={Secure(ClosureClients)}
        />
        <PrivateRoute
          exact
          path={['/entry-vacation']}
          component={Secure(Vacation)}
        />
        <PrivateRoute
          exact
          path={'/entry-report'}
          component={Secure(EntryReport)}
        />
        <PrivateRoute
          exact
          path={'/entry-report-fechamento-alocacaoRD'}
          component={Secure(EntryReportFechamentoRD)}
        />
        <PrivateRoute
          exact
          path={'/entry-report-fechamento-squadRD'}
          component={Secure(EntryReportFechamentoRDSquad)}
        />
        <PrivateRoute
          exact
          path={['/relatorio-ponto-rd']}
          component={Secure(PunchClockReportRD)}
        />
         <PrivateRoute
          exact
          path={['/conferencia-de-horas', '/conferencia-de-horas']}
          component={Secure(TimeCheck)}
        />
        <PrivateRoute
          exact
          path={['/fechamento-mensal', '/monthly-close']}
          component={Secure(MonthlyClose)}
        />
        <PrivateRoute exact path="/tasks" component={Secure(Tasks)} />
        <PrivateRoute
          exact
          path="/tasks-history"
          component={Secure(TasksHistory)}
        />
        <PrivateRoute exact path="/user-tasks" component={Secure(UserTask)} />
        <PrivateRoute exact path="/hour-bank" component={Secure(HourBank)} />
        <PrivateRoute exact path="/hour-bank-consult" component={Secure(HourBankConsult)} />
        <PrivateRoute
          exact
          path="/client-period"
          component={Secure(ClientPeriod)}
        />
        <PrivateRoute
          exact
          path="/user-period"
          component={Secure(UserPeriod)}
        />
        <PrivateRoute exact path="/proposal" component={Secure(Proposal)} />
        <PrivateRoute exact path="/pre-closure" component={Secure(PreClosure)} />
        <PrivateRoute exact path="/pre-closure-execution-user" component={Secure(PreClosureExecutionUser)} />
        <PrivateRoute
          exact
          path="/proposal-function"
          component={Secure(ProposalFunction)}
        />
        <PrivateRoute
          exact
          path="/proposal-user"
          component={Secure(ProposalUser)}
        />
        <PrivateRoute
          exact
          path="/filter-test"
          component={Secure(FilterTest)}
        />
        <PrivateRoute
          exact
          path="/consulta-colaborador"
          component={Secure(EmployeeSearch)}
        />

      </Switch>
    </BrowserRouter>
  )
}
